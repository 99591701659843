<template lang="pug">
  .section-title
    span.title {{ title }}
    span.required(v-if="required") {{ $t("plan_settings.basic_settings.req") }}
    AppTooltip(
      v-if="tooltip"
      :title="tooltip"
    )
</template>

<script>
  export default {
    props: {
      title: String,
      tooltip: String,
      required: Boolean
    },

    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .section-title
    align-items: center
    background: $default-gray-light
    border-bottom: $element-border
    display: flex
    padding: 7px 10px

    .title
      font-size: 0.8rem
      font-weight: 700

    .required
      background: $default-red
      border-radius: 4px
      color: $default-white
      font-size: 0.8rem
      line-height: 0.8rem
      margin-left: 10px
      font-weight: 400
      padding-block: 2px
      padding-inline: 6px
</style>
